import { QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ConnectAuthProvider } from '@imago-cloud/auth-client';
import { usePermission } from 'shared/hooks/usePermission';
import VtkProvider from 'shared/hooks/useVTKContext';
import { ImagoThemeProvider, queryClient } from 'shared/providers';
import { createAppRouter } from 'shared/routes/router';
import GlobalStyles from 'shared/styles/GlobalStyles';
import { GlobalDialog } from 'shared/ui/globalDialog';

import ErrorBoundary from './hoc/ErrorBoundary';
import { I18nHoc } from './hoc/I18nHoc';
import AuthHoc, { FallbackLoading } from './pages/Auth/AuthHoc';
import GlobalErrorDialog from './shared/ui/globalDialog/error/GlobalErrorDialog';

function App() {
  const { role } = usePermission();
  return (
    <QueryClientProvider client={queryClient}>
      <ImagoThemeProvider>
        <GlobalStyles />
        <ErrorBoundary>
          <ConnectAuthProvider>
            <AuthHoc>
              <Suspense fallback={<FallbackLoading />}>
                <VtkProvider>
                  <I18nHoc>
                    <GlobalErrorDialog />
                    <RouterProvider router={createAppRouter(role)} />
                  </I18nHoc>
                </VtkProvider>
              </Suspense>
            </AuthHoc>
          </ConnectAuthProvider>
        </ErrorBoundary>
        <GlobalDialog />
      </ImagoThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
