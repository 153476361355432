import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IMenuItem, IMenuItemChildren, INDEX_ROUTERS } from 'constants/menuItem';

export type UseNavList = ReturnType<typeof useNavList>;

export default function useNavList(list: IMenuItem | IMenuItemChildren) {
  const { pathname } = useLocation();
  const { children, path, index } = { children: undefined, index: false, ...list };
  const hasChildren = !!children;
  const active = getActive(path, pathname, hasChildren);
  const childActive = getChildActive(path, pathname, index);
  const [open, setOpen] = useState(active);

  useEffect(() => {
    setOpen(active);
  }, [active]);

  return { active, childActive, open, setOpen, hasChildren };
}

const getParent = (url: string) => url.split('/')[1];
export const getActive = (path: string, pathname: string, hasChildren: boolean) => {
  if (getParent(path) === getParent(pathname) && hasChildren) return true;
  if (getParent(path) === getParent(pathname) && INDEX_ROUTERS.includes(pathname)) return true;
  if (path === pathname) return true;
  return false;
};
const getChildActive = (path: string, pathname: string, index: boolean) => {
  const isIndexPage = pathname.split('/').length === 2;
  if (path === pathname) return true;
  if (getParent(path) === getParent(pathname) && index && isIndexPage) return true;

  return false;
};
