import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialogue,
  DialogueActions,
  DialogueContent,
  DialogueTitle,
  Typography,
} from '@imago-cloud/design-system';
import { List, ListItem, Stack } from '@mui/material';
import { $Address, $PhoneNumber } from 'client/schemas.gen';
import { useRecoilState } from 'recoil';
import { organizationDataState } from 'shared/atoms/organization/atoms';
import { ORGANIZATION_COUNTRY_LIST, ORGANIZATION_STATE_LIST } from 'shared/constants/organization';
import { FormProvider } from 'shared/ui';
import { SchemaFieldGroup } from 'shared/ui/formProvider/SchemaFieldGroup';
import { CorporateNumberField } from 'shared/ui/organization/CorporateNumberField';
import { RHFSelect, RHFTextField } from 'shared/ui/RHF';
import { handleNumberFieldInputValidate, handleZipCodeInputValidate } from 'shared/utils/inputHandler';
import { useGlobalDialog } from 'store';

import { usePostOrganization } from '../../../api/organization';
import { usePostUserQuery } from '../../../api/userQuery';
import useOrganizationHookFormHandling, { OrganizationFormType } from '../../hooks/useOrganizationHookFormHandling';

export const CompleteProfileDialogue = ({ state }: { state: 'lab' | 'user' }) => {
  const { t } = useTranslation();
  const { currentDialogInfo, removeDialog } = useGlobalDialog();

  const { methods, onBlur, commonTextFieldValidateProps } = useOrganizationHookFormHandling();
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const { mutateAsync: postOrganizationMutate, isPending: isPostOrganizationPending } = usePostOrganization();
  const [organizationData, setOrganizationData] = useRecoilState(organizationDataState);

  const { mutateAsync: postUserMutate, isPending: isPostUserPending } = usePostUserQuery();

  const organizationNameInput = useRef<HTMLDivElement | null>(null);
  const isFieldDisabled = !organizationData || (organizationData && Object.keys(organizationData).length > 0);

  const onSubmit = async (data: OrganizationFormType) => {
    let organizationId = '';
    const requestData = {
      name: data.name,
      address: data.address,
      corporateNumber: data.corporateNumber,
      businessPhone: data.businessPhone,
    };
    if (organizationData && Object.keys(organizationData).length > 0) {
      organizationId = organizationData.id ?? '';
    } else {
      await postOrganizationMutate(requestData, {
        onSuccess: (res) => {
          organizationId = res?.data?.id ?? '';
        },
      });
    }
    await postUserMutate({ organizationId });
    removeDialog(currentDialogInfo.id);
    window.location.reload();
  };

  useEffect(() => {
    setOrganizationData(null);
  }, []);

  return (
    <Dialogue
      open={true}
      PaperProps={{
        sx: {
          width: '540px !important',
          minWidth: '540px',
        },
      }}
    >
      <DialogueTitle>{t('dialog_complete_profile.title')}</DialogueTitle>
      <DialogueContent sx={{ paddingTop: 0, maxHeight: '800px' }}>
        <FormProvider id={'completeProfileDialogue'} methods={methods}>
          <Stack direction="column" gap="20px">
            <List sx={{ ml: '24px', padding: 0 }}>
              <ListItem
                sx={{
                  display: 'list-item',
                  listStyleType: 'disc',
                  p: 0,
                  wordBreak: 'break-word',
                }}
              >
                {t('dialog_search_organization.body1')}
              </ListItem>
              {state === 'user' && (
                <ListItem
                  sx={{
                    display: 'list-item',
                    listStyleType: 'disc',
                    p: 0,
                    wordBreak: 'break-word',
                  }}
                >
                  {t('dialog_search_organization.body2')}
                </ListItem>
              )}
            </List>
            <Stack direction="column" gap="60px">
              {/*corporate number*/}
              <CorporateNumberField organizationNameInput={organizationNameInput} onBlur={onBlur} />
              {/*Organization name*/}
              <Stack direction="column" gap="20px">
                <Typography variant="H16">{t('dialog_complete_profile.subtitle_organization_name')}</Typography>
                <RHFTextField
                  label={t('dialog_complete_profile.subtitle_organization_name') as string}
                  name="name"
                  label_visible="all"
                  inputProps={{ maxLength: 128 }}
                  rules={{ required: t('dialog_complete_profile.error_helper_text_organization_name') }}
                  disabled={isFieldDisabled}
                  inputRef={organizationNameInput}
                  onBlur={onBlur}
                />
              </Stack>
              {/*Organization address*/}
              <SchemaFieldGroup
                title={t('dialog_complete_profile.subtitle_office_address')}
                schema={$Address}
                disabled={isFieldDisabled}
                name={'address'}
                overrideProps={{
                  suburb: {
                    ...commonTextFieldValidateProps,
                    label: t('dialog_complete_profile.text_field_label_suburb/town/city') as string,
                  },
                  address: {
                    ...commonTextFieldValidateProps,
                    label: t('dialog_complete_profile.text_field_label_address_line_1') as string,
                  },
                  optionalAddress: {
                    ...commonTextFieldValidateProps,
                    label: t('dialog_complete_profile.text_field_address_line_2') as string,
                  },
                  zipCode: {
                    ...commonTextFieldValidateProps,
                    label: t('dialog_complete_profile.text_field_label_zip/postal_code') as string,
                    inputProps: {
                      ...commonTextFieldValidateProps.inputProps,
                      onInput: handleZipCodeInputValidate,
                    },
                  },
                }}
                overrideFields={{
                  country: (
                    <RHFSelect
                      label={t('dialog_complete_profile.text_field_label_country') as string}
                      name="address.country"
                      selectMenuItemList={ORGANIZATION_COUNTRY_LIST}
                      disabled={true}
                    />
                  ),
                  state: (
                    <RHFSelect
                      label={t('dialog_complete_profile.text_field_label_state/province/county') as string}
                      name="address.state"
                      selectMenuItemList={ORGANIZATION_STATE_LIST}
                      disabled={isFieldDisabled}
                      rules={{ required: 'State/Province/County is required.' }}
                    />
                  ),
                }}
              />
              {/*Phone number*/}
              <SchemaFieldGroup
                title={t('dialog_complete_profile.text_field_phone_number')}
                schema={$PhoneNumber}
                disabled={isFieldDisabled}
                fieldStyle={{ flexDirection: 'row', alignItems: 'flex-start', gap: '4px' }}
                name={'businessPhone'}
                overrideProps={{
                  number: {
                    onBlur,
                    label: t('dialog_complete_profile.text_field_phone_number') as string,
                    inputProps: {
                      maxLength: 28,
                      onInput: handleNumberFieldInputValidate,
                    },
                  },
                }}
                overrideFields={{
                  countryCode: (
                    <RHFSelect
                      label={t('dialog_complete_profile.text_field_label_country') as string}
                      name="businessPhone.countryCode"
                      selectMenuItemList={[{ value: '81', label: '+81' }]}
                      disabled={true}
                      sx={{ maxWidth: '110px' }}
                    />
                  ),
                }}
              />
            </Stack>
          </Stack>
        </FormProvider>
      </DialogueContent>
      <DialogueActions>
        <Button
          type="submit"
          size="36"
          disabled={!isValid}
          loading={isPostOrganizationPending || isPostUserPending}
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
          sx={{ minWidth: '100px' }}
        >
          {t('dialog_complete_profile.confirm')}
        </Button>
      </DialogueActions>
    </Dialogue>
  );
};
