import { useTranslation } from 'react-i18next';
import { Button } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { OrderDetailResponse } from 'client/types.gen';
import { CommonWrapper } from 'pages/OrderDetail/components/ActionComponents/CommonWrapper';
import {
  LAB_ORDER_RECEIVE,
  LAB_ORDER_REJECT,
  ORDER_DESIGN_APPROVE,
  ORDER_DESIGN_REJECT,
} from 'pages/OrderDetail/constants/dialog';
import { useInvitation } from 'shared/hooks/Sendbird/useCreateChannel';
import useGlobalDialog from 'store/globalDialog/useGlobalDialog';

import { MutationVariables, useOrderStatusChangeMutation } from '../../../../api/orderQuery';

const DEFAULT_MESSAGE_PARAMS = {
  message: 'The order has been placed. Feel free to reach out if you have any questions or need assistance.', // default
};

interface Props {
  orderId: string;
  role: 'receiver' | 'orderer';
  orderDetail?: OrderDetailResponse; // for creating chat
}

export const ConfirmRequired = ({ orderId, role, orderDetail }: Props) => {
  const { t } = useTranslation();
  // const onStartChat = useCreateChannel();
  // const onRetrieveChannels = useRetrieveChannelList();
  const { accept, decline } = useInvitation(orderDetail?.chattingChannelUrl as string);
  const { addDialog } = useGlobalDialog();
  const { mutateAsync } = useOrderStatusChangeMutation(orderId);

  const handleButtonClick = (action: 'reject' | 'approve') => {
    const actionsMap = {
      receiver: {
        reject: 'orderReject',
        approve: 'orderReceive',
      },
      orderer: {
        reject: 'designReject',
        approve: 'designConfirm',
      },
    };

    const dialogMap = {
      receiver: {
        reject: LAB_ORDER_REJECT,
        approve: LAB_ORDER_RECEIVE,
      },
      orderer: {
        reject: ORDER_DESIGN_REJECT,
        approve: ORDER_DESIGN_APPROVE,
      },
    };

    const dialog = dialogMap[role][action];

    addDialog(
      dialog(async () => {
        // Create Group Channel
        if (role === 'receiver') {
          if (action === 'approve' && orderDetail) {
            await mutateAsync({
              status: actionsMap[role][action] as MutationVariables['status'],
              body: { chattingChannelUrl: orderDetail.chattingChannelUrl },
            });
            await accept();
          } else if (action === 'reject') {
            await mutateAsync({
              status: actionsMap[role][action] as MutationVariables['status'],
            });
            await decline();
          }
        } else {
          await mutateAsync({
            status: actionsMap[role][action] as MutationVariables['status'],
          });
        }
      }),
    );
  };
  return (
    <CommonWrapper>
      <Stack flexDirection={'row'} sx={{ gap: '9px' }}>
        <Button size={48} color={'error'} sx={{ width: '50%' }} onClick={() => handleButtonClick('reject')}>
          {t('patient_page_status.btn_reject')}
        </Button>
        <Button size={48} sx={{ width: '50%' }} onClick={() => handleButtonClick('approve')}>
          {t('patient_page_status.btn_receive')}
        </Button>
      </Stack>
    </CommonWrapper>
  );
};
