import React, { ChangeEvent, MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Divider,
  IconButton,
  MenuItem,
  Ricon,
  Select,
  TextField,
  theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';
import { BridgeClip } from 'assets/index';
import { hook, shadeType } from 'client/types.gen';
import humanizeString from 'humanize-string';
import { NewProductDisplayItemType } from 'pages/NewOrder/components/Prescription/Prescription';
import { FINISH_OPTIONS_BUTTONS, SHADE_OPTION } from 'pages/NewOrder/constants/prescription';
import useNewOrderDataContext from 'pages/NewOrder/context/useNewOrderDataContext';
import { CustomSelectorToFDI, FDIToPalmer } from 'shared/utils/convertToPalmer';

const HOOK_OPTION = [
  { label: 'product_list.none', value: 'NONE' },
  { label: 'product_list.mesiolingual', value: 'MESIOLINGUAL' },
  { label: 'product_list.mesiobuccal', value: 'MESIOBUCCAL' },
  { label: 'product_list.distolingual', value: 'DISTOLINGUAL' },
  { label: 'product_list.distobuccal', value: 'DISTOBUCCAL' },
];
// TODO: GET Shade from api

export type NewProductItemProp = {
  item: NewProductDisplayItemType;
};

// TODO: useState 3개 없앨 수 있는지 확인.
export default function NewProductItem({ item }: NewProductItemProp) {
  const { t } = useTranslation();

  const { toothNumber, prosthesis, method, material, shape, requireScanBodyAndImplantInfo } = item;

  const { pathname } = useLocation();
  const isViewOnly = pathname.includes('remake');

  const {
    orderData: { orderItems },
    setOrderData,
  } = useNewOrderDataContext();
  const {
    shadeType: shadeSelected,
    hook: hookSelected,
    premiumFinishing: isPremiumFinishing,
    scanBodyDetail,
    implantDetail,
  } = item;

  const currentItem = orderItems.find((orderItem) => item.toothNumber === orderItem.toothNumber)!;
  const nextItem = orderItems.find((orderItem) => item.toothNumber + 1 === orderItem.toothNumber);
  // TODO: 11-21, 31-41 보여주도록 처리

  const isBridgeWithNextItem = currentItem.groupId !== undefined && currentItem.groupId === nextItem?.groupId;

  const handleRemoveOrderItemClick = () => {
    // TODO: 브릿지 제거 처리.
    setOrderData((prev) => {
      const itemToRemove = prev.orderItems.find((item) => item.toothNumber === toothNumber);
      if (itemToRemove?.groupId !== undefined && itemToRemove.groupType === 'BRIDGE') {
        // Do something...
        // 브릿지 넘버 뽑아서 handleBridgeClick() 에 넘겨준 후 제거.
      }

      const updatedOrderItemList = prev.orderItems.filter((item) => item.toothNumber !== toothNumber);

      return { ...prev, orderItems: updatedOrderItemList };
    });
  };

  const handleShadeChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;

    const updatedOrderItems = orderItems.map((item) => {
      if (item.toothNumber === toothNumber) {
        return { ...item, shadeType: value as shadeType };
      }
      return item;
    });
    setOrderData((prev) => ({ ...prev, orderItems: updatedOrderItems }));
  };

  const handleHookChange = (e: ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value as hook;

    const updatedOrderItems = orderItems.map((item) => {
      if (item.toothNumber === toothNumber) {
        return { ...item, hook: value };
      }
      return item;
    });
    setOrderData((prev) => ({ ...prev, orderItems: updatedOrderItems }));
  };

  const handleFinishOptionChange = (_e: MouseEvent, value: boolean) => {
    const updatedOrderItems = orderItems.map((item) => {
      if (item.toothNumber === toothNumber) {
        return { ...item, premiumFinishing: value };
      }
      return item;
    });
    setOrderData((prev) => ({ ...prev, orderItems: updatedOrderItems }));
  };

  const handleImplantScanBodyInfoChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    title: 'ScanBody' | 'Implant',
  ) => {
    const { value } = e.target as HTMLInputElement;

    if (title === 'Implant') {
      const updatedItems = orderItems.map((item) => {
        if (item.toothNumber === toothNumber) {
          return { ...item, implantDetail: value };
        }
        return item;
      });
      return setOrderData((prev) => ({ ...prev, orderItems: updatedItems }));
    } else if (title === 'ScanBody') {
      const updatedItems = orderItems.map((item) => {
        if (item.toothNumber === toothNumber) {
          return { ...item, scanBodyDetail: value };
        }
        return item;
      });
      return setOrderData((prev) => ({ ...prev, orderItems: updatedItems }));
    }
  };

  return (
    <>
      <Stack
        gap="20px"
        flexDirection="row"
        sx={{
          width: '100%',
          padding: '20px 24px 24px',
          backgroundColor: theme.palette.common.white,
          borderRadius: '8px',
        }}
      >
        {!isViewOnly && (
          <Box>
            <IconButton
              onClick={handleRemoveOrderItemClick}
              size="28"
              color="grey900"
              icon_size="small"
              variant="outlined"
            >
              <Ricon icon="ri-close-line" />
            </IconButton>
          </Box>
        )}

        <Box sx={{ width: '100%' }}>
          <Box>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              <Typography variant="H16">{prosthesis}</Typography>
              {!isViewOnly && (
                <Button size="20" variant="text2" onClick={() => {}}>
                  {t('product_list.btn_edit')}
                </Button>
              )}
            </Stack>
            <Box>
              <Typography variant="Subtitle_Semibold14" color={theme.palette.text.secondary}>
                <Trans
                  i18nKey={FDIToPalmer[CustomSelectorToFDI[toothNumber]]?.full}
                  values={{ variable: FDIToPalmer[CustomSelectorToFDI[toothNumber]]?.num ?? '##' }}
                />
              </Typography>
            </Box>

            <Box>
              <Typography
                variant="Subtitle_Semibold14"
                color={theme.palette.text.secondary}
              >{`${humanizeString(method)} - ${humanizeString(material)} - ${humanizeString(shape)}`}</Typography>
            </Box>
          </Box>

          <Divider />

          <Stack justifyContent="space-between" alignItems="center" gap="12px" sx={{ position: 'relative' }}>
            {/*  Shade */}
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
              <Box>
                <Typography variant="Subtitle_Semibold14">
                  <Trans
                    i18nKey={'product_list.shade'}
                    components={{
                      u: <span style={{ color: theme.palette.error.main }} />,
                    }}
                  />
                </Typography>
              </Box>

              <Box>
                <Select variant="outlined" size="26" value={shadeSelected} onChange={handleShadeChange}>
                  {SHADE_OPTION.map(({ label }) => {
                    return (
                      <MenuItem key={label} value={label}>
                        {label === 'NONE' ? humanizeString(label) : label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Stack>

            {/* Hook */}
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
              <Box>
                <Typography variant="Subtitle_Semibold14">{t('product_list.hook')}</Typography>
              </Box>

              <Box>
                <Select
                  variant="outlined"
                  size="26"
                  onChange={handleHookChange}
                  value={hookSelected}
                  label_visible="none"
                >
                  {HOOK_OPTION.map(({ label, value }) => (
                    <MenuItem key={label} value={value}>
                      {t(label)}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Stack>

            {/* Finish option */}
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
              <Box>
                <Typography variant="Subtitle_Semibold14">{t('product_list.finish_options')}</Typography>
              </Box>
              <Stack flexDirection="row" alignItems="center" gap="8px">
                <ToggleButtonGroup separate value={isPremiumFinishing}>
                  {FINISH_OPTIONS_BUTTONS.map(({ value, label }) => {
                    const isSelected = isPremiumFinishing === value;
                    return (
                      <ToggleButton
                        key={label}
                        size="32"
                        variant="outlined"
                        selected={isSelected}
                        value={value}
                        sx={{ padding: '10px' }}
                        onClick={handleFinishOptionChange}
                      >
                        <Typography variant="Button_Bold14" sx={{ color: isSelected ? 'blue.500' : 'text.primary' }}>
                          <Trans
                            i18nKey={label}
                            components={{
                              b: (
                                <span
                                  style={{
                                    color: isSelected ? theme.palette.blue.main : theme.palette.text.primary,
                                    fontSize: '10px',
                                    fontWeight: 600,
                                    lineHeight: '14px',
                                    marginTop: '1px',
                                  }}
                                />
                              ),
                            }}
                          />
                        </Typography>
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </Stack>
            </Stack>

            {/* Scanbody */}
            {requireScanBodyAndImplantInfo && (
              <Stack justifyContent="space-between" alignItems="flex-start" gap="12px" sx={{ width: '100%' }}>
                <Stack sx={{ width: '100%' }}>
                  <Box>
                    <Typography variant="Subtitle_Semibold14">{t('product_list.scanbody')}</Typography>
                  </Box>
                  <TextField
                    value={scanBodyDetail}
                    onChange={(e) => handleImplantScanBodyInfoChange(e, 'ScanBody')}
                    size="36"
                    sx={{ width: '100%' }}
                    multiline
                    placeholder={t('product_list.text_holder_enter_scanbody_information') as string}
                  />
                </Stack>
                <Stack sx={{ width: '100%' }}>
                  <Box>
                    <Typography variant="Subtitle_Semibold14">{t('product_list.implant')}</Typography>
                  </Box>
                  <TextField
                    value={implantDetail}
                    onChange={(e) => handleImplantScanBodyInfoChange(e, 'Implant')}
                    size="36"
                    sx={{ width: '100%' }}
                    placeholder={t('product_list.text_holder_implant') as string}
                    multiline
                  />
                </Stack>
              </Stack>
            )}
            {isBridgeWithNextItem && (
              <Box sx={{ position: 'absolute', bottom: '-50px' }}>
                <Button size="36" sx={{ background: theme.palette.common.black }} endIcon={<BridgeClip />}>
                  {t('label_bridge.body')}
                </Button>
              </Box>
            )}
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
