import { useTranslation } from 'react-i18next';
import { Button, Loading, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { OrderShippingCreateRequest } from 'client/types.gen';
import { EditShippingInformationDialogue } from 'pages/NewOrder/components/Submit/EditShippingInformationDialogue';
import { SubmitOrderInfoTitle } from 'pages/NewOrder/components/Submit/SubmitOrder';
import { useDialogue } from 'shared/hooks/useDialogue';

import { ORGANIZATION_STATE_MAP } from '../../../../shared/constants/organization';

type ShippingInformationType = {
  shippingData: OrderShippingCreateRequest;
};
export const ShippingInformation = ({ shippingData }: ShippingInformationType) => {
  const { t } = useTranslation();
  const { open, handleOpenDialogue, handleCloseDialogue } = useDialogue();

  return (
    <>
      <Stack direction="column" gap="12px">
        <SubmitOrderInfoTitle title={t('submit_panel.shipping_information')} />
        <Stack
          sx={{
            width: '100%',
            minHeight: '164px',
            padding: '20px 24px',
            borderRadius: '8px',
            backgroundColor: 'grey.100',
            justifyContent: 'center',
          }}
          gap="4px"
        >
          {!shippingData ? (
            <Loading type="basic" size="20" />
          ) : (
            <>
              <Typography variant="Subtitle_Semibold16">{`${shippingData?.receiver?.name?.firstName} ${shippingData?.receiver?.name?.lastName}`}</Typography>
              <Typography variant="Body14" color="text.secondary">
                {`${
                  shippingData?.shippingAddress?.optionalAddress
                    ? `${shippingData?.shippingAddress?.optionalAddress}, `
                    : ''
                }${shippingData?.shippingAddress?.address}`}
                <br />
                {`${shippingData?.shippingAddress?.suburb}, ${t(ORGANIZATION_STATE_MAP[shippingData?.shippingAddress?.state] || '')}, ${t('office_address.text')} (${shippingData?.shippingAddress?.zipCode})`}
              </Typography>
              <Typography variant="Body14" color="text.secondary">
                {`+${shippingData?.receiver?.number?.countryCode} ${shippingData?.receiver?.number?.number}`}
              </Typography>
              <Stack alignItems="flex-end">
                <Button size="22" font="bold" variant="text2" onClick={handleOpenDialogue}>
                  {t('submit_panel.btn_edit')}
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      {open && <EditShippingInformationDialogue open={open} onClose={handleCloseDialogue} />}
    </>
  );
};
