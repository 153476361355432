import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem, Ricon, theme, Tooltip, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { OrderDetailResponse, OrderRemakeHistory } from 'client/types.gen';
import dayjs from 'dayjs';
import { usePopover } from 'shared/hooks';
import { getFormatDateWithDash, getFormattedDateShortDashed } from 'shared/utils/formatDate';
import { formattedAddress } from 'shared/utils/formatString';

import { useRemakeHistoryQuery } from '../../../../api/orderQuery';
import { ORGANIZATION_STATE_MAP } from '../../../../shared/constants/organization';

export const PatientArea = ({ orderDetail }: { orderDetail: OrderDetailResponse }) => {
  const { t } = useTranslation();
  const { patient, lab, originalOrderId, id: currentOrderId, canBeRemade } = orderDetail;

  const { visitDate, tryInDate, age, name, gender, birth } = patient;

  const remakeOrderId = canBeRemade ? (originalOrderId ? currentOrderId : '') : currentOrderId;
  const { data: remakeHistoryResponse } = useRemakeHistoryQuery(remakeOrderId);
  const remakeHistory = remakeHistoryResponse?.data;

  const renderTooltipContent = () => (
    <Stack>
      <Typography variant="H14" sx={{ maxWidth: '344px' }}>
        {lab?.organization.name}
      </Typography>
      <Typography variant="Body14" sx={{ maxWidth: '366px' }}>
        {lab?.organization &&
          formattedAddress({
            ...lab.organization.address,
            state: t(ORGANIZATION_STATE_MAP[lab.organization.address.state] || ''),
            country: t('office_address.text'),
          })}
      </Typography>
    </Stack>
  );

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.background.white,
        p: '24px',
      }}
    >
      <Stack flexDirection="row" justifyContent={'space-between'}>
        <Typography
          variant={'H24'}
          sx={{
            maxWidth: '517px',
            maxHeight: '34px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {name}
        </Typography>
        <Tooltip disableHoverListener={!lab?.organization} title={renderTooltipContent()} placement="left">
          <Stack sx={{ justifyContent: 'center' }}>
            <Ricon
              icon={'ri-home-gear-line'}
              svgProps={{ fill: theme.palette.text.thirdary, width: 28, height: 28, pointerEvents: 'all' }}
            />
          </Stack>
        </Tooltip>
      </Stack>
      <Stack sx={{ position: 'relative' }}>
        <Typography variant={'Subtitle_Semibold14'} sx={{ color: theme.palette.text.secondary, mt: '8px' }}>
          {t(`patient_page_info.patient_info_${gender?.toLowerCase()}`, {
            variable1: dayjs(birth).format('YYMMDD'),
            variable2: age || '-',
          })}
        </Typography>
        <Typography
          variant={'Body14'}
          sx={{ color: theme.palette.text.secondary, mt: '4px' }}
        >{`${t('patient_page_info.title_try_in_date')} ${tryInDate ? getFormatDateWithDash(tryInDate) : '-'}`}</Typography>
        <Typography
          variant={'Body14'}
          sx={{ color: theme.palette.text.secondary }}
        >{`${t('patient_page_info.title_visit_date')} ${visitDate ? getFormatDateWithDash(visitDate) : '-'}`}</Typography>
        {remakeHistory && <RemakeHistory currentOrderId={currentOrderId} remakeHistory={remakeHistory} />}
      </Stack>
    </Stack>
  );
};

const RemakeHistory = ({
  currentOrderId,
  remakeHistory,
}: {
  currentOrderId: string;
  remakeHistory: OrderRemakeHistory[];
}) => {
  const { t } = useTranslation();
  const { open, handleClose, id, anchorRef, handleAnchorRef } = usePopover();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const basePath = pathname.slice(0, pathname.lastIndexOf('/'));

  const remakeList = processedRemakeList(remakeHistory, currentOrderId);
  return (
    <>
      <Button
        variant={'outlined'}
        color={'grey'}
        size={'30'}
        endIcon={<Ricon icon={'ri-arrow-down-s-fill'} />}
        sx={{ position: 'absolute', bottom: 0, right: 0 }}
        onClick={handleAnchorRef}
      >
        {t('patient_page_info.btn_remake')}
      </Button>
      <Menu
        anchorEl={anchorRef}
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { width: '160px', p: '8px', borderRadius: '8px', marginTop: '24px' } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {remakeList.map(({ displayDate, orderId, isDisabled, isSelected }) => (
          <MenuItem
            key={orderId}
            onClick={() => {
              handleClose();
              navigate(`${basePath}/${orderId}`);
            }}
            disabled={isDisabled}
            selected={isSelected}
          >
            {displayDate}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const processedRemakeList = (remakeOrderList: OrderRemakeHistory[], currentOrderId: string) => {
  //YYYY-MM-DD 기준 중복날짜를 처리
  const dateCountMap: Record<string, number> = {};

  remakeOrderList.forEach((item) => {
    const formattedDate = getFormattedDateShortDashed(item.createdAt);
    dateCountMap[formattedDate] = (dateCountMap[formattedDate] || 0) + 1;
  });

  const modifyDuplicateDate = remakeOrderList.map((item) => {
    const formattedDate = getFormattedDateShortDashed(item.createdAt);

    // 역순으로 접미사를 붙이기 위해 인스턴스 카운트를 감소
    const instanceIndex = --dateCountMap[formattedDate];
    const duplicateSuffix = instanceIndex > 0 ? `(${instanceIndex})` : '';

    const isDisabled = new Date().getTime() - new Date(item.createdAt).getTime() > 3 * 365 * 24 * 60 * 60 * 1000; // 3년 기준

    return {
      ...item,
      displayDate: `${formattedDate} ${duplicateSuffix}`.trim(),
      isDisabled,
      isSelected: currentOrderId === item.orderId,
    };
  });
  return modifyDuplicateDate;
};
