export const ORGANIZATION_STATE_LIST = [
  { label: 'address.aichiken', value: 'Aichi-ken' },
  { label: 'address.akitaken', value: 'Akita-ken' },
  { label: 'address.aomoriken', value: 'Aomori-ken' },
  { label: 'address.chibaken', value: 'Chiba-ken' },
  { label: 'address.ehimeken', value: 'Ehime-ken' },
  { label: 'address.fukuiken', value: 'Fukui-ken' },
  { label: 'address.fukuokaken', value: 'Fukuoka-ken' },
  { label: 'address.fukushimaken', value: 'Fukushima-ken' },
  { label: 'address.gifuken', value: 'Gifu-ken' },
  { label: 'address.gunmaken', value: 'Gunma-ken' },
  { label: 'address.hiroshimaken', value: 'Hiroshima-ken' },
  { label: 'address.hokkaidoken', value: 'Hokkai-dō' },
  { label: 'address.hyogoken', value: 'Hyōgo-ken' },
  { label: 'address.ibarakiken', value: 'Ibaraki-ken' },
  { label: 'address.ishikawaken', value: 'Ishikawa-ken' },
  { label: 'address.iwateken', value: 'Iwate-ken' },
  { label: 'address.kagawaken', value: 'Kagawa-ken' },
  { label: 'address.kagoshimaken', value: 'Kagoshima-ken' },
  { label: 'address.kanagawaken', value: 'Kanagawa-ken' },
  { label: 'address.kochiken', value: 'Kōchi-ken' },
  { label: 'address.kumamotoken', value: 'Kumamoto-ken' },
  { label: 'address.kyotofu', value: 'Kyōto-fu' },
  { label: 'address.mieken', value: 'Mie-ken' },
  { label: 'address.miyagiken', value: 'Miyagi-ken' },
  { label: 'address.miyazakiken', value: 'Miyazaki-ken' },
  { label: 'address.naganoken', value: 'Nagano-ken' },
  { label: 'address.nagasakiken', value: 'Nagasaki-ken' },
  { label: 'address.naraken', value: 'Nara-ken' },
  { label: 'address.niigataken', value: 'Nīgata-ken' },
  { label: 'address.oitaken', value: 'Ōita-ken' },
  { label: 'address.okayamaken', value: 'Okayama-ken' },
  { label: 'address.okinawaken', value: 'Okinawa-ken' },
  { label: 'address.osakafu', value: 'Ōsaka-fu' },
  { label: 'address.sagaken', value: 'Saga-ken' },
  { label: 'address.saitamaken', value: 'Saitama-ken' },
  { label: 'address.shigaken', value: 'Shiga-ken' },
  { label: 'address.shimaneken', value: 'Shimane-ken' },
  { label: 'address.shizuokaken', value: 'Shizuoka-ken' },
  { label: 'address.tochigiken', value: 'Tochigi-ken' },
  { label: 'address.tokushimaken', value: 'Tokushima-ken' },
  { label: 'address.tokyoto', value: 'Tōkyō-to' },
  { label: 'address.tottoriken', value: 'Tottori-ken' },
  { label: 'address.toyamaken', value: 'Toyama-ken' },
  { label: 'address.wakayamaken', value: 'Wakayama-ken' },
  { label: 'address.yamagataken', value: 'Yamagata-ken' },
  { label: 'address.yamaguchiken', value: 'Yamaguchi-ken' },
  { label: 'address.yamanashiken', value: 'Yamanashi-ken' },
];

type StateMap = { [key: string]: string };
export const ORGANIZATION_STATE_MAP = ORGANIZATION_STATE_LIST.reduce<StateMap>((acc, { label, value }) => {
  acc[value] = label;
  return acc;
}, {});

export const ORGANIZATION_COUNTRY_LIST = [{ label: 'office_address.text', value: 'Japan' }];
