import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@imago-cloud/design-system';
import { Stack, SxProps } from '@mui/material';
import humanizeString from 'humanize-string';
import { RHFTextField } from 'shared/ui/RHF';
import RHFSelect from 'shared/ui/RHF/RHFSelect';

type SchemaProperty = {
  title?: string;
  readOnly?: boolean;
  enum?: readonly string[];
  type: string;
  format?: string;
};

type SchemaType = {
  properties: Record<string, SchemaProperty>;
  required?: readonly string[];
};

export const SchemaFieldGroup = ({
  title,
  schema,
  overrideFields,
  overrideProps,
  disabled = false,
  isViewOnly = false,
  name,
  fieldStyle,
  titleVariant = 'H16',
}: {
  title: string;
  schema: SchemaType;
  overrideFields?: Record<string, ReactNode>;
  overrideProps?: Record<string, Object>;
  disabled?: boolean;
  isViewOnly?: boolean;
  name?: string;
  fieldStyle?: SxProps;
  titleVariant?: React.ComponentProps<typeof Typography>['variant'];
}) => {
  const { t } = useTranslation();

  const FormFields = Object.entries(schema.properties).map(([fieldName, fieldInfo]) => {
    const fieldLabel = fieldInfo.title ?? humanizeString(fieldName);
    const fieldCommonProps = {
      label: fieldLabel,
      name: name ? `${name}.${fieldName}` : fieldName,
      disabled: fieldInfo.readOnly ?? disabled,
      rules: {
        required:
          schema.required?.includes(fieldName) &&
          t(`dialog_complete_profile.error_helper_text_${convertErrorText(fieldName)}`),
      },
    };

    if (overrideFields && overrideFields[fieldName]) {
      return <div key={fieldName}>{overrideFields[fieldName]}</div>;
    }
    if (fieldInfo.enum) {
      return (
        <RHFSelect
          key={fieldName}
          selectMenuItemList={fieldInfo.enum.map((value: string) => ({ label: value, value: value }))}
          {...fieldCommonProps}
          {...(overrideProps && overrideProps[fieldName])}
        />
      );
    }
    return (
      <RHFTextField
        key={fieldName}
        {...fieldCommonProps}
        {...(overrideProps && overrideProps[fieldName])}
        isViewOnly={isViewOnly}
        disabled={disabled}
      />
    );
  });

  return (
    <Stack direction="column" gap="20px">
      {title && <Typography variant={titleVariant}>{title}</Typography>}
      <Stack direction="column" gap="20px" sx={{ ...fieldStyle }}>
        {FormFields}
      </Stack>
    </Stack>
  );
};

export const convertErrorText = (fieldName: string) => {
  switch (fieldName) {
    case 'state':
      return 'state/province/county';
    case 'suburb':
      return 'suburb/town/city';
    case 'address':
      return 'address_line_1';
    case 'zipCode':
      return 'zip_/_postal_code';
    case 'number':
      return 'phone_number';
    default:
      return fieldName;
  }
};
