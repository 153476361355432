import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconButton, Loading, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { grey } from '@imago-cloud/design-system/lib/esm/colors';
import { InputProps, Stack } from '@mui/material';
import { $Organization, $OrganizationCreateRequest } from 'client/schemas.gen';
import { useRecoilState } from 'recoil';
import { organizationDataState } from 'shared/atoms/organization/atoms';
import { SchemaFieldGroup } from 'shared/ui/formProvider/SchemaFieldGroup';
import { handleNumberFieldInputValidate } from 'shared/utils/inputHandler';

import { useGetOrganizationUsingCorporateNumber } from '../../../api/organization';

type CorporateNumberFieldProps = {
  organizationNameInput: React.RefObject<HTMLDivElement | null>;
  onBlur: InputProps['onBlur'];
};

export const CorporateNumberField = ({ organizationNameInput, onBlur }: CorporateNumberFieldProps) => {
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useGetOrganizationUsingCorporateNumber();

  const [organizationData, setOrganizationData] = useRecoilState(organizationDataState);
  const [isOrganizationSelectionActive, setIsOrganizationSelectionActive] = useState(false);
  const { watch, reset, setValue } = useFormContext();

  const handleCorporateNumberSearch = async () => {
    const corporateNumber = watch('corporateNumber');
    if (!corporateNumber) return;

    setIsOrganizationSelectionActive(false);
    reset({
      address: {
        country: 'Japan',
        countryCode: '81',
      },
      corporateNumber: watch('corporateNumber'),
    });
    const organizationDataResponse = await mutateAsync(corporateNumber);
    if (Array.isArray(organizationDataResponse?.data)) {
      setOrganizationData(organizationDataResponse.data.find((org) => org.corporateNumber === corporateNumber) || {});
    }
    // const data = await ky
    //   .create({})
    //   .get('https://api.zipaddress.net/', { searchParams: { zipcode: corporateNumber } })
    //   .json();
    // console.log(data);
  };

  const handleSearchedOrganizationSelect = () => {
    setIsOrganizationSelectionActive(true);
    if (organizationData && Object.keys(organizationData).length > 0) {
      reset({
        corporateNumber: watch('corporateNumber'),
        name: organizationData.name,
        address: organizationData.address,
        businessPhone: organizationData.businessPhone,
      });
    }
  };

  return (
    <Stack direction="column">
      <SchemaFieldGroup
        title={t('dialog_search_organization.subtitle_corporate_number')}
        schema={{
          ...$OrganizationCreateRequest,
          properties: {
            corporateNumber: {
              ...$Organization.properties.corporateNumber,
            },
          },
        }}
        overrideProps={{
          corporateNumber: {
            label: t('dialog_search_organization.search_corporate_number'),
            helperText:
              organizationData &&
              Object.keys(organizationData).length === 0 &&
              t('dialog_complete_profile.corporate_number_error'),
            inputProps: {
              maxLength: 13,
              onInput: handleNumberFieldInputValidate,
            },
            onBlur: onBlur,
            error: organizationData && Object.keys(organizationData).length === 0,
            InputProps: {
              endAdornment: isPending ? (
                <IconButton size="24" variant="transparent" data-cy="searchLoading">
                  <Loading type="basic" size="14" />
                </IconButton>
              ) : (
                <IconButton
                  size="36"
                  variant="transparent"
                  data-cy="submitSearch"
                  onClick={handleCorporateNumberSearch}
                >
                  <Ricon
                    icon="ri-search-line"
                    svgProps={{
                      fill: grey[800],
                      width: 24,
                      height: 24,
                    }}
                  />
                </IconButton>
              ),
            },
            onChange: (e: any) => {
              setValue('corporateNumber', e.target.value);
            },
            onKeyDown: (e: any) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleCorporateNumberSearch();
              }
            },
          },
        }}
      />
      {organizationData && Object.keys(organizationData).length > 0 && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: '16px',
            marginTop: '8px',
            backgroundColor: isOrganizationSelectionActive ? 'blue.10' : 'grey.100',
            borderRadius: '8px',
            border: isOrganizationSelectionActive ? '1px solid' : '',
            borderColor: isOrganizationSelectionActive ? 'primary.main' : '',
          }}
        >
          <Stack direction="column" sx={{ width: '400px' }}>
            <Typography variant="Subtitle_Semibold14" color={isOrganizationSelectionActive ? 'primary.main' : ''}>
              {organizationData?.name}
            </Typography>
            <Typography
              variant="Body14"
              color={isOrganizationSelectionActive ? 'primary.main' : ''}
            >{`${organizationData?.address?.state}, ${organizationData?.address?.suburb}`}</Typography>
          </Stack>
          <IconButton
            size="32"
            color="grey800"
            variant="transparent2"
            onClick={handleSearchedOrganizationSelect}
            sx={{
              '&:hover svg': {
                fill: isOrganizationSelectionActive ? theme.palette.primary.main : grey[800],
              },
            }}
          >
            <Ricon
              icon="ri-checkbox-circle-fill"
              svgProps={{
                fill: isOrganizationSelectionActive ? theme.palette.primary.main : theme.palette.action.disabled,
                width: 32,
                height: 32,
              }}
            />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};
