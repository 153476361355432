import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { useEffect, useState } from 'react';
import { GroupChannelListQuery, GroupChannelModule } from '@sendbird/chat/groupChannel';

type UseCreateChannelProps = {
  orderId: string;
  ordererId: string;
  // partnerLabId: string;
  patientName: string;
};

export function useSendbirdSdkInstance() {
  const { stores } = useSendbirdStateContext();
  const sdk = stores.sdkStore.sdk;
  // as SendBirdInstance | undefined; // Sendbird SDK instance

  useEffect(() => {
    const setInvitationPreference = async () => {
      const autoAccept = false;
      try {
        const res = await sdk.setChannelInvitationPreference(autoAccept);
        console.log('Channel invitation set: ', res);
      } catch (error) {
        // Handle error.
        console.error('Error setting channel invitation preference:', error);
      }
    };
    if (sdk && sdk.currentUser) {
      setInvitationPreference();
    }
  }, [sdk]);

  if (!sdk) throw new Error('Sendbird SDK is not initialized');

  return sdk;
}

// when user(, or PL) placed an order.
export function useCreateChannel() {
  const sdk = useSendbirdSdkInstance();

  return async ({ orderId, ordererId, patientName }: UseCreateChannelProps) => {
    if (!sdk.groupChannel) {
      console.log('SDK groupChannel not initialised');
      return;
    }
    const coverImage = await getCoverImage();

    const channelParams = {
      name: patientName,
      channelUrl: orderId,
      invitedUserIds: [ordererId, 'QC'],
      isDistinct: false,
      operatorUserIds: ['QC'],

      // customType: orderId, // -> createMeta()
      coverImage,
    };

    return await sdk.groupChannel.createChannel(channelParams);
  };
}

export function useInvitation(channelUrl: string) {
  const sdk = useSendbirdSdkInstance();
  const groupChannel = sdk?.groupChannel;

  const accept = async () => {
    const channel = await groupChannel?.getChannel(channelUrl);
    if (!channel) {
      throw new Error('Channel not found');
    }
    await channel.acceptInvitation();
  };

  const decline = async () => {
    const channel = await groupChannel?.getChannel(channelUrl);
    if (!channel) {
      throw new Error('Channel not found');
    }
    const res = await channel.declineInvitation();
    console.log(res);
  };

  return { accept, decline };
}

// export function useRetrieveChannelList() {
//   const { stores } = useSendbirdStateContext();
//   const sb = stores?.sdkStore?.sdk;
//   // as SendBirdInstance | undefined;
//
//   const [isSdkReady, setIsSdkReady] = useState(false);
//
//   useEffect(() => {
//     if (sb) {
//       setIsSdkReady(true);
//     }
//   }, [sb]);
//
//   // 그룹 채널 목록을 불러오는 함수 정의
//   return () =>
//     new Promise(async (resolve, reject) => {
//       if (!isSdkReady || !sb || !sb.groupChannel) {
//         console.log('!not initailised');
//         // return reject(new Error('Sendbird SDK가 초기화되지 않았습니다.'));
//         return;
//       }
//
//       const groupChannelListQuery: GroupChannelListQuery = sb.groupChannel.createMyGroupChannelListQuery();
//       // @ts-ignore
//       groupChannelListQuery.includeEmpty = true;
//       // @ts-ignore
//       groupChannelListQuery.limit = 20;
//
//       const channels = await groupChannelListQuery.next();
//       resolve(channels);
//     });
// }

export const useUnreadMessageCountAll = () => {
  const sdk = useSendbirdSdkInstance();
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  const getUnreadMessageCount = async () => {
    try {
      const count = await sdk?.groupChannel.getTotalUnreadMessageCount();
      setUnreadMessageCount(count);
      return count;
    } catch (err) {
      setUnreadMessageCount(0);
      return 0;
    }
  };

  useEffect(() => {
    const groupChannel = sdk?.groupChannel;
    if (!sdk || !groupChannel) return;
    getUnreadMessageCount();
  }, [sdk]);

  return { unreadMessageCount, getUnreadMessageCount };
};

export const useUnreadChannelMessageCount = (channelUrl: string | undefined) => {
  const sdk = useSendbirdSdkInstance();
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const groupChannel = sdk?.groupChannel;

  const getUnreadMessageCount = async () => {
    if (!channelUrl) return null;

    try {
      const channel = await groupChannel?.getChannel(channelUrl);
      if (!channel) return 0;

      const count = channel.unreadMessageCount;
      setUnreadMessageCount(count);
      return count;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  useEffect(() => {
    if (!sdk || !groupChannel) return;
    getUnreadMessageCount();

    return () => {
      setUnreadMessageCount(0);
    };
  }, [sdk, channelUrl, groupChannel]);

  return { unreadMessageCount, getUnreadMessageCount };
};

// coverImage
async function getCoverImage() {
  const res = await fetch('/DentbirdChatLogo.png');
  const blob = await res.blob();
  return new File([blob], 'Dentbirdlogo.png', { type: blob.type });
}

export const useChannelInfo = (channelUrl: string) => {};
