import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Divider, IconButton, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { alpha, Box, Stack } from '@mui/material';
import { $Patient } from 'client/schemas.gen';
import Printing3DModelDialogue from 'pages/NewOrder/components/ImportData/Printing3DModelDialogue';
import useNewOrderDataContext from 'pages/NewOrder/context/useNewOrderDataContext';
import { useDialogue } from 'shared/hooks/useDialogue';
import { SchemaFieldGroup } from 'shared/ui/formProvider/SchemaFieldGroup';
import { RHFDatePicker, RHFSelect, RHFTextField } from 'shared/ui/RHF';

import { handleNumberFieldInputValidate } from '../../../../shared/utils/inputHandler';
import { GENDER_OPTIONS, ORDER_TYPE_RANGE, PATIENT_INFO_FORM } from '../../constants/importData';

const textFieldStyle = {
  display: 'flex',
  alignItems: 'center',
};

export default function PatientInfoForm() {
  const { t } = useTranslation();
  const { open, handleCloseDialogue, handleOpenDialogue } = useDialogue();
  const { orderData, setOrderData } = useNewOrderDataContext();
  const isRemake = window.location.pathname.includes('remake');
  const {
    printingFilesDisplay,
    patient: { gender },
  } = orderData;

  const {
    watch,
    formState: { errors, dirtyFields },
  } = useFormContext();

  const [designConfirmProcessIncluded, setDesignConfirmProcessIncluded] = useState(
    orderData.designConfirmProcessIncluded,
  );

  const isManufacture = watch('designManufactureCombination').includes('MANUFACTURE');
  const ManufactureOnly = watch('designManufactureCombination') === 'MANUFACTURE';

  const today = new Date();

  return (
    <>
      <Box sx={{ maxWidth: '584px' }}>
        <Box sx={{ padding: '32px 40px 10px' }}>
          {/*<Typography variant="H16">Patient information</Typography>*/}

          <SchemaFieldGroup
            title={'Patient Information'}
            schema={$Patient}
            disabled={isRemake}
            overrideFields={{
              name: (
                <Stack gap="8px" sx={{ mb: '-20px' }}>
                  <Stack direction="row">
                    <Typography variant="Subtitle_Semibold14" color={theme.palette.text.secondary}>
                      <Trans
                        i18nKey={'patient_information.patient_name'}
                        components={{
                          u: <span style={{ color: theme.palette.error.main }} />,
                        }}
                      />
                    </Typography>
                  </Stack>

                  <RHFTextField
                    label={undefined}
                    sx={{ width: '100%' }}
                    id={PATIENT_INFO_FORM.patientName.name}
                    name={PATIENT_INFO_FORM.patientName.name}
                    rules={PATIENT_INFO_FORM.patientName.rules}
                    placeholder={t('list_header.patient_name') as string}
                    disabled={isRemake}
                    size="36"
                  />
                </Stack>
              ),
              age: <></>,
              birth: (
                <Stack direction="row" gap="40px">
                  {/* Birthdate */}
                  <Stack sx={{ width: '100%' }} gap="8px">
                    <Stack direction="row">
                      <Typography variant="Subtitle_Semibold14" color={theme.palette.text.secondary}>
                        <Trans
                          i18nKey={'patient_information.birthdate'}
                          components={{
                            u: <span style={{ color: theme.palette.error.main }} />,
                          }}
                        />
                      </Typography>
                    </Stack>
                    <RHFDatePicker
                      value={watch(PATIENT_INFO_FORM.birthDate.name)}
                      name={PATIENT_INFO_FORM.birthDate.name}
                      placeholder={PATIENT_INFO_FORM.birthDate.placeholder}
                      inputFormat={'YYYY/MM/DD'}
                      disableFuture={true}
                      rules={PATIENT_INFO_FORM.birthDate.rules}
                      disabled={isRemake}
                    />
                  </Stack>

                  {/* Age */}
                  <Stack sx={{ width: '100%' }} gap="8px">
                    <Typography variant="Subtitle_Semibold14" color={theme.palette.text.secondary}>
                      <label htmlFor={PATIENT_INFO_FORM.age.name}>{t(PATIENT_INFO_FORM.age.key)}</label>
                    </Typography>
                    <RHFTextField
                      label={undefined}
                      sx={{ width: '230px' }}
                      id={PATIENT_INFO_FORM.age.name}
                      name={PATIENT_INFO_FORM.age.name}
                      placeholder={t(PATIENT_INFO_FORM.age.key) as string}
                      isViewOnly={isRemake}
                      inputProps={{
                        maxLength: 10,
                        onInput: handleNumberFieldInputValidate,
                      }}
                      size="36"
                    />
                  </Stack>
                </Stack>
              ),
              gender: (
                <Stack sx={{ width: '100%' }} gap="8px">
                  <Typography variant="Subtitle_Semibold14" color={theme.palette.text.secondary}>
                    {t(PATIENT_INFO_FORM.gender.key)}
                  </Typography>
                  <RHFSelect
                    label=""
                    name={PATIENT_INFO_FORM.gender.name}
                    selectMenuItemList={GENDER_OPTIONS}
                    onSelectChange={() => {
                      setOrderData((prev) => ({
                        ...prev,
                        patient: {
                          ...prev.patient,
                          gender: watch(PATIENT_INFO_FORM.gender.name),
                        },
                      }));
                    }}
                    size="36"
                  />
                </Stack>
              ),
              tryInDate: (
                <Stack direction="row" justifyContent="flext-start" alignItems="center" gap="40px">
                  <Stack gap="8px" sx={{ width: '100%' }}>
                    <Typography variant="Subtitle_Semibold14" color={theme.palette.text.secondary} sx={textFieldStyle}>
                      {t(PATIENT_INFO_FORM.tryInDate.key)}
                    </Typography>
                    <Stack direction="row" gap="12px">
                      {/*<TextField sx={{ width: '230px' }} />*/}
                      <RHFDatePicker
                        value={watch(PATIENT_INFO_FORM.tryInDate.name)}
                        name={PATIENT_INFO_FORM.tryInDate.name}
                        placeholder={PATIENT_INFO_FORM.tryInDate.placeholder}
                        inputFormat={'YYYY/MM/DD'}
                        minDate={today}
                      />
                      {/*</TextField>*/}
                    </Stack>
                  </Stack>

                  <Stack gap="8px" sx={{ width: '100%' }}>
                    <Typography variant="Subtitle_Semibold14" color={theme.palette.text.secondary} sx={textFieldStyle}>
                      {t(PATIENT_INFO_FORM.visitDate.key)}
                    </Typography>
                    {/*<Stack direction="row" gap="12px">*/}
                    {/*<TextField sx={{ width: '230px' }}>*/}
                    <RHFDatePicker
                      value={watch(PATIENT_INFO_FORM.visitDate.name)}
                      name={PATIENT_INFO_FORM.visitDate.name}
                      placeholder={PATIENT_INFO_FORM.visitDate.placeholder}
                      inputFormat={'YYYY/MM/DD'}
                      minDate={today}
                    />
                    {/*</TextField>*/}
                    {/*</Stack>*/}
                  </Stack>
                </Stack>
              ),
              visitDate: <></>,
            }}
          />
        </Box>

        <Divider />

        {/* Order Type */}
        <Box sx={{ padding: '24px 40px' }}>
          <Stack gap="16px">
            <Typography variant="H16">{t('patient_information.order_type')}</Typography>
            <RHFSelect
              disabled={isRemake}
              label=""
              name={'designManufactureCombination'}
              selectMenuItemList={ORDER_TYPE_RANGE}
              onSelectChange={() => {
                setOrderData((prev) => ({
                  ...prev,
                  patientPrintingFiles: [],
                  printingFilesDisplay: [],
                }));
              }}
              sx={{ width: '94%' }}
              size="36"
              // groupSx={{ flexDirection: 'row', gap: '16px' }}
            />
          </Stack>
        </Box>

        <Divider />

        <Box sx={{ padding: '24px 40px' }}>
          <Stack gap="16px">
            <Typography variant="H16">{t('patient_information.additional_options')}</Typography>

            <Stack gap="20px">
              {/* Printing 3D model */}
              {isManufacture && (
                <Stack gap="8px">
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="Subtitle_Semibold14">
                      {t('patient_information.3d_model_(+extra_fee)')}
                    </Typography>
                    <Button
                      size="36"
                      color="grey"
                      hover_color="black"
                      variant="outlined"
                      startIcon={<Ricon icon={'ri-add-circle-fill'} />}
                      onClick={handleOpenDialogue}
                    >
                      {t('patient_information.btn_select_files')}
                    </Button>
                  </Stack>
                  {printingFilesDisplay?.length !== 0 && (
                    <Stack
                      gap="4px"
                      sx={{
                        width: '100%',
                        height: '100%',
                        padding: '12px 14px',
                        borderRadius: '8px',
                        backgroundColor: theme.palette.grey[100],
                      }}
                    >
                      {printingFilesDisplay?.map((fileNameString) => (
                        <Stack
                          key={fileNameString}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ width: '100%' }}
                        >
                          <Typography variant="Body14">{fileNameString}</Typography>
                          <IconButton
                            variant="transparent"
                            size="18"
                            onClick={() => {
                              console.log('click');
                              const updated = printingFilesDisplay?.filter((fileName) => fileName !== fileNameString);
                              setOrderData((prev) => ({ ...prev, printingFilesDisplay: updated }));
                            }}
                          >
                            <Ricon svgProps={{ width: '18px', height: '18px' }} icon={'ri-close-fill'} />
                          </IconButton>
                        </Stack>
                      ))}
                    </Stack>
                  )}
                </Stack>
              )}

              {/* Design confirm process */}
              {!ManufactureOnly && (
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="Subtitle_Semibold14">
                    {t('patient_information.design_confirm_process')}
                  </Typography>
                  <Button
                    disabled={isRemake}
                    // TODO: Design confirm for order, not individual product?
                    onClick={() => {
                      setDesignConfirmProcessIncluded((prev) => !prev);
                      setOrderData((prev) => ({
                        ...prev,
                        designConfirmProcessIncluded: !prev.designConfirmProcessIncluded,
                      }));
                    }}
                    size="36"
                    color="primary"
                    variant="outlined"
                    value={designConfirmProcessIncluded}
                    startIcon={
                      <Ricon
                        icon={'ri-checkbox-circle-fill'}
                        svgProps={{
                          fill: isRemake
                            ? theme.palette.action.disabled
                            : designConfirmProcessIncluded
                              ? theme.palette.primary.main
                              : theme.palette.common.black,
                        }}
                      />
                    }
                    sx={{
                      border: `1px solid ${designConfirmProcessIncluded ? theme.palette.primary.main : theme.palette.grey['500']}`,
                      backgroundColor: designConfirmProcessIncluded
                        ? alpha(theme.palette.primary.main, 0.32)
                        : theme.palette.common.white,
                      color: designConfirmProcessIncluded
                        ? theme.palette.primary.main
                        : isRemake
                          ? theme.palette.action.disabled
                          : theme.palette.common.black,
                    }}
                  >
                    {t('patient_information.btn_include')}
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Box>
        <Divider />
      </Box>

      <Printing3DModelDialogue open={open} onClose={handleCloseDialogue} />
    </>
  );
}
