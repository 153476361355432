import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { $Address, $PhoneNumber } from 'client/schemas.gen';
import { ORGANIZATION_COUNTRY_LIST, ORGANIZATION_STATE_LIST } from 'shared/constants/organization';
import { usePermission } from 'shared/hooks/usePermission';
import { FormProvider } from 'shared/ui';
import { FileType, FileUploadWithPreview } from 'shared/ui/fileUpload/FileUploadWithPreview';
import { FILE_UPLOAD_GUIDELINES } from 'shared/ui/fileUpload/ImageFileUpload';
import { SchemaFieldGroup } from 'shared/ui/formProvider/SchemaFieldGroup';
import { RHFSelect, RHFTextField } from 'shared/ui/RHF';
import { handleNumberFieldInputValidate, handleZipCodeInputValidate } from 'shared/utils/inputHandler';
import { useTopAlert } from 'store';

import { useUploadFile } from '../../api/fileQuery';
import { usePatchOrganization } from '../../api/organization';
import { useUserQuery } from '../../api/userQuery';
import useOrganizationHookFormHandling, {
  OrganizationFormType,
} from '../../shared/hooks/useOrganizationHookFormHandling';

const fieldNameMap: Record<string, string> = {
  name: 'organization_name.title_organization_name',
  address: 'Organization_address.title',
  businessPhone: 'organization.subtitle_phone_number',
};

export default function Organization() {
  const { t } = useTranslation();
  const { setTopAlert } = useTopAlert();

  const { data: userData } = useUserQuery();
  const organization = userData.data?.organization;

  const { mutateAsync: patchOrganizationMutate, isPending: isPatchOrganizationPending } = usePatchOrganization();
  const { mutateAsync: fileUploadMutate, isPending: isFileUploadPending } = useUploadFile();

  const [file, setFile] = useState<FileType>(organization?.certificateOfIncorporation ?? null);
  const [isFileDirty, setIsFileDirty] = useState(false);

  const { accessiblePermissions } = usePermission();

  const { methods, onBlur, commonTextFieldValidateProps } = useOrganizationHookFormHandling();
  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty, dirtyFields },
  } = methods;

  const isOnlyReadPermission = !organization || !accessiblePermissions.includes('connect:organization:write');

  const getSortedChangedFieldNames = (fields: string[]): string[] => {
    const fieldNames = Object.keys(fieldNameMap);
    return fields
      .sort((a, b) => fieldNames.indexOf(a) - fieldNames.indexOf(b)) // fieldNameMap 순서대로 정렬
      .map((field) => t(fieldNameMap[field]) || field); // 필드명을 변환
  };

  const onSubmit = async (formData: OrganizationFormType) => {
    const changedFields = getSortedChangedFieldNames(Object.keys(dirtyFields)).join(', ');
    try {
      const newFileId = file
        ? file instanceof File
          ? (await fileUploadMutate({ file })).data?.id ?? null
          : file.id
        : null;
      const requestData = {
        name: formData.name,
        address: formData.address,
        corporateNumber: formData.corporateNumber,
        certificateOfIncorporationFileId: newFileId,
        businessPhone: formData.businessPhone,
      };
      await patchOrganizationMutate(
        { data: requestData, organizationId: organization?.id ?? '' },
        {
          onSuccess: () => {
            setTopAlert({
              open: true,
              variant: 'filled',
              severity: 'success',
              title: t('update_alert.title') as string,
              description: t('update_alert.subtitle', {
                variable1:
                  (changedFields ? `${changedFields}` : '') +
                  (isFileDirty ? `${changedFields ? ', ' : ''}${t('certificate_of_incorporation.title')}` : ''),
              }) as string,

              // description: `${changedFields}${isFileDirty ? (changedFields ? ', ' : '') + t('certificate_of_incorporation.title') : ''} has been updated.`,
            });
            setIsFileDirty(false);
            reset(formData);
          },
        },
      );
    } catch (e) {}
  };

  return (
    <FormProvider id={'organizationInfo'} methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" sx={{ gap: '60px', width: '400px', p: '40px 0 124px 40px' }}>
        <Stack direction="column" gap="20px">
          <Typography variant="H18">{t('organization_name.title_organization_name')}</Typography>
          <RHFTextField
            label={t('organization_name.placeholder_organization_name') as string}
            name="name"
            label_visible="all"
            inputProps={{ maxLength: 128 }}
            rules={{ required: t('dialog_complete_profile.error_helper_text_organization_name') }}
            disabled={isOnlyReadPermission}
            onBlur={onBlur}
          />
        </Stack>
        {/*Organization address*/}
        <SchemaFieldGroup
          title={t('Organization_address.title')}
          schema={$Address}
          disabled={isOnlyReadPermission}
          titleVariant={'H18'}
          name={'address'}
          overrideProps={{
            suburb: { ...commonTextFieldValidateProps, label: t('office_address.place_holder_suburb/town/city') },
            address: { ...commonTextFieldValidateProps, label: t('office_address.text_field_label_address_line_1') },
            optionalAddress: {
              ...commonTextFieldValidateProps,
              label: t('office_address.place_holderaddress_line_2_(optional)'),
            },
            zipCode: {
              ...commonTextFieldValidateProps,
              label: t('office_address.place_holderzip_/_postal_code'),
              inputProps: {
                ...commonTextFieldValidateProps.inputProps,
                onInput: handleZipCodeInputValidate,
              },
            },
          }}
          overrideFields={{
            country: (
              <RHFSelect
                label={t('office_address.text_field_label_country') as string}
                name="address.country"
                selectMenuItemList={ORGANIZATION_COUNTRY_LIST}
                disabled={true}
              />
            ),
            state: (
              <RHFSelect
                label={t('office_address.text_field_label_state/province/county') as string}
                name="address.state"
                selectMenuItemList={ORGANIZATION_STATE_LIST}
                disabled={isOnlyReadPermission}
                rules={{ required: 'State/Province/County is required.' }}
              />
            ),
          }}
        />
        {/*Phone number*/}
        <SchemaFieldGroup
          title={t('organization.subtitle_phone_number')}
          schema={$PhoneNumber}
          disabled={isOnlyReadPermission}
          titleVariant={'H18'}
          fieldStyle={{ flexDirection: 'row', alignItems: 'flex-start', gap: '4px' }}
          name={'businessPhone'}
          overrideProps={{
            number: {
              onBlur,
              label: t('organization.subtitle_phone_number'),
              inputProps: {
                maxLength: 28,
                onInput: handleNumberFieldInputValidate,
              },
            },
          }}
          overrideFields={{
            countryCode: (
              <RHFSelect
                label={t('organization.text_field_label_country') as string}
                name="businessPhone.countryCode"
                selectMenuItemList={[{ value: '81', label: '+81' }]}
                disabled={true}
                sx={{ width: '110px' }}
              />
            ),
          }}
        />
        <Stack direction="column" gap="20px">
          <Typography variant="H18">{t('corporate_number.title')}</Typography>
          <RHFTextField
            label={t('corporate_number.title') as string}
            name="corporateNumber"
            label_visible="focus"
            disabled={true}
          />
        </Stack>

        <Stack direction="column" gap="20px">
          <Typography variant="H18">{t('certificate_of_incorporation.title')}</Typography>
          <FileUploadWithPreview
            file={file}
            setFile={(newFile) => {
              setFile(newFile);
              setIsFileDirty(true);
            }}
            description={FILE_UPLOAD_GUIDELINES.ORGANIZATION}
            isDisabled={isOnlyReadPermission}
          />
        </Stack>

        <Button
          size="48"
          color="primary"
          variant="contained"
          type="submit"
          disabled={!(isValid && (isDirty || isFileDirty)) || isOnlyReadPermission}
          sx={{ minWidth: '108px' }}
        >
          {t('organization.btn_update')}
        </Button>
      </Stack>
    </FormProvider>
  );
}
