import React from 'react';
import { FieldPath, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialogue,
  DialogueActions,
  DialogueContent,
  DialogueTitle,
  Typography,
} from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { $Address, $PhoneNumber } from 'client/schemas.gen';
import useNewOrderDataContext from 'pages/NewOrder/context/useNewOrderDataContext';
import { ORGANIZATION_COUNTRY_LIST, ORGANIZATION_STATE_LIST } from 'shared/constants/organization';
import { ICloseButtonOnlyDialogueProps } from 'shared/hooks/useDialogue';
import { FormProvider } from 'shared/ui';
import { SchemaFieldGroup } from 'shared/ui/formProvider/SchemaFieldGroup';
import { RHFSelect, RHFTextField } from 'shared/ui/RHF';
import { handleNumberFieldInputValidate, handleZipCodeInputValidate } from 'shared/utils/inputHandler';

import { OrderShippingCreateRequest } from '../../../../client';

export const EditShippingInformationDialogue = ({ open, onClose }: ICloseButtonOnlyDialogueProps) => {
  const { t } = useTranslation();
  const { shippingData, setShippingData } = useNewOrderDataContext();

  const methods = useForm<OrderShippingCreateRequest>({
    mode: 'onSubmit',
    defaultValues: {
      shippingAddress: {
        country: shippingData.shippingAddress.country ?? 'Japan',
        state: shippingData.shippingAddress.state ?? '',
        suburb: shippingData.shippingAddress.suburb ?? '',
        address: shippingData.shippingAddress.address ?? '',
        optionalAddress: shippingData.shippingAddress.optionalAddress ?? '',
        zipCode: shippingData.shippingAddress.zipCode ?? '',
      },
      receiver: {
        name: shippingData.receiver.name ?? '',
        number: shippingData.receiver.number ?? '',
      },
    },
  });
  const {
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { isValid, isDirty },
  } = methods;

  const onSubmit = async (data: OrderShippingCreateRequest) => {
    setShippingData((prev) => ({
      ...prev,
      shippingAddress: data.shippingAddress,
      receiver: data.receiver,
    }));
    onClose();
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const name = e.target.name as FieldPath<OrderShippingCreateRequest>;
    const value = watch(name);
    if (typeof value === 'string') {
      setValue(name, value.trim());
    }
    trigger(name);
  };

  const commonTextFieldValidateProps = {
    onBlur,
    inputProps: {
      maxLength: 128,
    },
  };

  return (
    <Dialogue
      open={open}
      PaperProps={{
        sx: {
          width: '540px !important',
          minWidth: '540px',
        },
      }}
    >
      <DialogueTitle>{t('dialog_edit_shipping_info.title')}</DialogueTitle>
      <DialogueContent sx={{ paddingTop: '16px !important', maxHeight: '784px' }}>
        <FormProvider id={'shippingInformationEditDialogue'} methods={methods}>
          <Stack direction="column" gap="60px">
            {/*Name*/}
            <Stack direction="column" gap="20px">
              <Typography variant="H16">{t('dialog_edit_shipping_info.shipping_information')}</Typography>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap="12px">
                <RHFTextField
                  label={t('dialog_edit_shipping_info.first_name') as string}
                  name="receiver.name.firstName"
                  rules={{
                    required: t('edit_shipping_information.please_enter_first_name'),
                  }}
                  inputProps={{ maxLength: 128 }}
                  onBlur={onBlur}
                />
                <RHFTextField
                  label={t('dialog_edit_shipping_info.last_name') as string}
                  name="receiver.name.lastName"
                  rules={{
                    required: t('edit_shipping_information.please_enter_last_name'),
                  }}
                  inputProps={{ maxLength: 128 }}
                  onBlur={onBlur}
                />
              </Stack>
            </Stack>
            {/*<OrganizationAddressField />*/}
            <SchemaFieldGroup
              title={t('dialog_edit_shipping_info.subtitle_office_address') as string}
              schema={$Address}
              name={'shippingAddress'}
              overrideProps={{
                suburb: { ...commonTextFieldValidateProps, label: t('dialog_edit_shipping_info.suburb/town/city') },
                address: {
                  ...commonTextFieldValidateProps,
                  label: t('dialog_edit_shipping_info.text_field_label_address_line_1'),
                },
                optionalAddress: {
                  ...commonTextFieldValidateProps,
                  label: t('dialog_edit_shipping_info.text_field_label_address_line_2_(optional)'),
                },
                zipCode: {
                  ...commonTextFieldValidateProps,
                  label: t('dialog_edit_shipping_info.zip_/_postal_code'),
                  inputProps: {
                    ...commonTextFieldValidateProps.inputProps,
                    onInput: handleZipCodeInputValidate,
                  },
                },
              }}
              overrideFields={{
                country: (
                  <RHFSelect
                    label={t('dialog_edit_shipping_info.text_field_label_country') as string}
                    name="shippingAddress.country"
                    selectMenuItemList={ORGANIZATION_COUNTRY_LIST}
                    disabled={true}
                  />
                ),
                state: (
                  <RHFSelect
                    label={t('dialog_edit_shipping_info.state/province/county') as string}
                    name="shippingAddress.state"
                    selectMenuItemList={ORGANIZATION_STATE_LIST}
                    rules={{ required: 'State/Province/County is required.' }}
                  />
                ),
              }}
            />
            <SchemaFieldGroup
              title={t('dialog_edit_shipping_info.subtitle_phone_number') as string}
              schema={$PhoneNumber}
              fieldStyle={{ flexDirection: 'row', alignItems: 'flex-start', gap: '4px' }}
              name={'receiver.number'}
              overrideProps={{
                number: {
                  onBlur,
                  label: t('dialog_edit_shipping_info.phone_number'),
                  inputProps: {
                    maxLength: 28,
                    onInput: handleNumberFieldInputValidate,
                  },
                },
              }}
              overrideFields={{
                countryCode: (
                  <RHFSelect
                    label={t('dialog_edit_shipping_info.country') as string}
                    name="receiver.number.countryCode"
                    selectMenuItemList={[{ value: '81', label: '+81' }]}
                    disabled={true}
                    sx={{ width: '110px' }}
                  />
                ),
              }}
            />
          </Stack>
        </FormProvider>
      </DialogueContent>
      <DialogueActions>
        <Button size="36" color="grey" variant="outlined" sx={{ minWidth: '100px' }} onClick={onClose}>
          {t('dialog_edit_shipping_info.btn_cancel.93')}
        </Button>
        <Button
          type="submit"
          disabled={!isValid || !isDirty}
          size="36"
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
          sx={{ minWidth: '100px' }}
        >
          {t('dialog_edit_shipping_info.btn_update.22')}
        </Button>
      </DialogueActions>
    </Dialogue>
  );
};
