import '@sendbird/uikit-react/dist/index.css';
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import { GroupChannelHeader } from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import { UseQueryResult } from '@tanstack/react-query';
import React from 'react';
import { IconButton, Ricon } from '@imago-cloud/design-system';

import { useUploadOtherFile } from '../../../api/fileQuery';

export default function ViewerChatWindow({
  chatInfo,
  onClose,
  isOpen,
}: {
  chatInfo: { orderId: string; chattingChannelUrl: string; refetch: () => Promise<UseQueryResult> };
  onClose: () => void;
  isOpen: boolean;
}) {
  const { orderId, chattingChannelUrl, refetch } = chatInfo;
  const handleBeforeSendFileMessage = useUploadOtherFile(orderId, refetch);

  if (!isOpen) return null;

  return (
    <div
      className="sendbird-app__wrap"
      style={{ position: 'absolute', right: '20px', top: '20px', width: '540px', height: '760px' }}
    >
      <div className="sendbird-app__conversation-wrap" style={{ width: '100%' }}>
        <GroupChannel
          channelUrl={chattingChannelUrl}
          onBackClick={onClose}
          onChatHeaderActionClick={onClose}
          onBeforeSendFileMessage={handleBeforeSendFileMessage}
          renderChannelHeader={(props) => {
            return (
              <GroupChannelHeader
                renderRight={() => (
                  <IconButton onClick={onClose} size="32" icon_size="small" color="grey900" variant="transparent">
                    <Ricon icon="ri-close-line" svgProps={{ width: '24px', height: '24px' }} />
                  </IconButton>
                )}
              />
            );
          }}
        />
      </div>
    </div>
  );
}
